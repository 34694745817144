import Link from "next/link"
import LoggedOutLayout from "@/components/layouts/LoggedOutLayout"
import LoginForm from "@/components/pages/login/LoginForm"
import styles from "@/styles/LoggedOutLayout.module.scss"
import typo from "@/styles/Typography.module.scss"
import colors from "@/styles/Colors.module.scss"
import classNames from "classnames"
import useAccount from "@/hooks/api-hooks/useAccount"
import DisabledDialog from "@/components/dialogs/DisabledDialog"
import dynamic from "next/dynamic"
import notifications from "public/animations/notifications.json"

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

export default function IndexPage() {
  localStorage.clear()
  const { error } = useAccount()

  return (
    <LoggedOutLayout title="Log In">
      <main className={styles.body}>
        <div className={styles.left}>
          <Lottie animationData={notifications} style={{ height: 200, width: 200 }} />
          <div className={styles.text}>
            <h1 className={classNames(typo.h4, colors.slate)}>
              Welcome to
            </h1>
            <h1 className={classNames(typo.h3, colors.blue)}>
              NextMe 2.0
            </h1>
            <br />
            <p className={classNames(typo.large, colors.slate)}>
              NextMe is a modern virtual waiting room that makes it simple for businesses to manage their waitlist and serve more customers. Eliminate long lines and create amazing first impressions, all while saving money on outdated technology.
            </p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center">
          <LoginForm />

          <p className={styles.textUnderForm}>
            New to NextMe?{" "}
            <Link href="/signup">
              <a className={styles.link} id="signupLink">
                Sign Up for Free
              </a>
            </Link>
          </p>
        </div>
      </main>
      <DisabledDialog open={error?.response.status === 403} />
    </LoggedOutLayout>
  )
}
